@import "variables";

@mixin btn-pattern-blue() {
  text-transform: uppercase;
  color: #fff;
  font-size: 22px;
  padding: 10px 20px;
  background: $blue-gradient;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  text-decoration: none;
  &:hover {
    background: $hover-blue-gradient;
  }
}

@mixin card-title() {
  .card-title {
    font-size: 40px;
    margin-top: 0;
    font-weight: 400;
    margin-bottom: 10px;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
}

@mixin load-more() {
  .btn-pattern-tabs.btn-read-more {
    display: block;
    margin: 35px auto 50px;
    text-align: center;

    .btn-pattern-tabs {
      border: 1px solid #d0d0d0;
      color: #000;
      text-transform: uppercase;
      position: relative;
      padding: 10px 10px 10px 40px;
      font-size: 18px;
      background: transparent;
      border-radius: 10px;
      text-decoration: none;
      font-weight: 700;

      &:before {
        content: '';
        position: absolute;
        left: 12px;
        top: calc(50% - 10px);
        width: 20px;
        height: 20px;
        background-image: url(/assets/img/arrow-icon.png);
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        transition: all .3s;
        transform: rotate(
                        0deg
        );
      }

      &:hover {
        background: #d0d0d0;
        color: #000;

        &:before {
          transition: all .5s;
          transform: rotate(
                          360deg
          );
        }
      }
    }

  }
  @media (max-width: 768px) {

    .btn-pattern-tabs.btn-read-more .btn-pattern-tabs {
      font-size: 3vw;

      &:before {
        background-size: 10px;
      }
    }
  }
}

@mixin title-container() {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 28px;
  padding: 50px 0 50px;
  display: block;
  text-align: center;
  width: 100%;
}

@mixin slider-nav() {
  .nav-item {
    position: absolute;
    top: calc(50% - 50px);
  }

  .nav-prev {
    left: 40px;
    border-top: 5px solid #565656;
    border-left: 5px solid #565656;
    transform: rotate(
                    -45deg
    );
    width: 35px;
    height: 35px;
  }

  .nav-next {
    right: 40px;
    border-right: 5px solid #565656;
    border-bottom: 5px solid #565656;
    transform: rotate(
                    -50deg
    );
    width: 35px;
    height: 35px;
  }
  @media (max-width: 992px) {

    .nav-prev {
      left: 0;
      border-top: 2px solid #565656;
      border-left: 2px solid #565656;
      width: 20px;
      height: 20px;
    }

    .nav-next {
      right: 0;
      border-right: 2px solid #565656;
      border-bottom: 2px solid #565656;
      width: 20px;
      height: 20px;
    }
  }
}

@mixin page-bit-title() {

  font-size: 40px;
  margin-bottom: 35px;
  margin-top: 20px;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
    @media (max-width: 768px) {
      font-size: 24px;
      font-weight: 400;
    }
}
@mixin texts(){
  h2 {
    font-size: 34px;
    line-height: 44px;

    margin-bottom: 25px;
    font-weight: 700;
  }
  h3{
    font-size: 20px;
    line-height: 24px;

    margin-bottom: 20px;
    font-weight: 700;

  }
  p {
    font-size: 16px;
    line-height: 24px;

    margin-bottom: 20px;
  }
  ol, ul{
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 15px;

  }
  ul {
    list-style: square;
    padding-left: 20px;

    li {
      margin-bottom: 20px;
      font-size: 16px;

      &:last-child {
        margin-bottom: 0;
      }

    }

  }
  ol{
    li {
      margin-bottom: 8px;
      font-size: 16px;
      &:last-child {
        margin-bottom: 0;
      }

    }
  }
}
