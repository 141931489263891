@import "variables";
@import "mixins";

.welcome-page {
  background: #f6f6f6;

  .banner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    min-height: 450px;
    background-size: cover;
    background-position: center center;
    position: relative;

    .banner-img {
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &:before {
      z-index: 1;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #00000070;
    }

    .rent-form-wrapper {
      width: 100%;
      max-width: 450px;
      min-height: 420px;
    }

    .banner-info {
      z-index: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;
      top: 100px;
      min-height: 430px;

      &:before {
        content: '';
        position: absolute;
        background-image: url(/assets/img/toyota.png);
        background-size: cover;
        background-position: center center;
        width: 575px;
        height: 310px;
        top: 8em;
        left: 19em;
      }

      .mob-btn {
        display: none;
      }

      .info-text {
        .title-big {
          text-transform: uppercase;
          font-size: 45px;
          color: #fff;
          display: block;
          width: 100%;
          text-align: right;
          font-weight: 900;
        }

        .title-sm {
          font-size: 30px;
          color: #fff;
          padding: 20px 0 30px;
          display: block;
          width: 100%;
          text-align: right;
          font-weight: 900
        }

        .btn-pattern-blue {
          justify-content: flex-end;
          display: flex;

          a {
            text-transform: uppercase;
            color: #fff;
            font-size: 22px;
            padding: 10px 20px;
            background: $blue-gradient;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            text-decoration: none;
            transition: 0.3s;

            &:hover {
              background: $hover-blue-gradient;
            }

          }
        }

        .search-block {
          position: absolute;
          right: 0;
          bottom: 22px;
          width: 215px;
          max-width: 215px;

          .search-label {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              left: 10px;
              top: 0;
              z-index: 1;
              width: 20px;
              height: 20px;
              background-image: url(/assets/img/search-icon.png);
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center center;
            }

            span.none {
              position: absolute;
              left: -9999px;
              top: -9999px;
              opacity: 0;
              display: block;
              width: 0px;
              height: 0px;
              background: transparent;
            }
          }

          input {
            border-radius: 10px;
            width: 215px;
            max-width: 215px;
            border: 1px solid #a7a7a7;
            padding: 15px 20px 15px 40px;
            position: relative;
            outline: none;
            transition: all .3s;

            &:focus {
              border: 1px solid #000;
              transition: all .3s;
            }
          }
        }

      }
    }
  }

  .auto-tabs {
    padding-top: 150px;

    .tabs-nav {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      border-bottom: 2px solid #adaeb0;
      overflow-x: auto;

      li {
        max-width: 220px;
        width: 100%;

        a {
          width: 100%;
          font-weight: 300;
          z-index: 10;
          background: linear-gradient(to top, #212d81, #303e97);
          cursor: pointer;
          position: relative;
          border-radius: 10px 10px 0 0;
          color: #fff;
          text-transform: uppercase;
          font-size: 19px;
          line-height: 24px;
          display: inline-block;
          list-style-type: none;
          flex-grow: 1;
          text-align: center;

          padding: 10px;

          &:hover {
            background: #f2d026;
            color: #000;
          }
        }

        &.uk-active {
          a {
            background: #f2d026;
            color: #000;
          }
        }
      }
    }

    .tabs-content {
      .cars {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .item {
          width: 100%;
          max-width: 360px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          border-radius: 10px;
          overflow: hidden;
          background: linear-gradient(to top, #dcdcdc, #fff 60%);
          padding: 25px 0;
          margin: 20px 0;

          .car-link {
            max-height: 190px;
            display: block;
            padding: 10px;

            img {
              object-fit: cover;
              height: 100%;
              width: initial;
              max-height: 190px;


            }
          }

          .car-name {
            font-size: 26px;
            font-weight: 700;
            display: block;
            width: 100%;
            text-align: center;
            padding: 20px 10px;
          }

          .car-prise {
            font-size: 30px;
            font-weight: 300;
            display: block;
            width: 100%;
            text-align: center;
            padding: 0 0 50px;

            b {
              padding: 0 5px;
              font-size: 34px;
              font-weight: 700;
            }
          }

          .btn-pattern-tabs.btn-tabs {
            display: flex;
            justify-content: center;

            .btn-pattern-tabs {
              font-size: 22px;
              padding: 10px 10px;
              background: transparent;
              border: 3px solid #323f9b;
              color: #000;
              text-transform: uppercase;
              border-radius: 10px;
              text-decoration: none;
              font-weight: 900;

              &:hover {
                color: white;
                background: $blue-gradient;
              }
            }
          }
        }
      }
    }

    @include load-more;
  }

  .upper-price {

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    min-height: 450px;
    background-size: cover;
    background-position: center 15%;
    position: relative;
    padding-bottom: 60px;

    .banner-img {
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }


    &:before {
      z-index: 1;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
    }

    .title-container {
      @include title-container;
      color: white;

    }

    .items-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;

      .item {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        max-width: 200px;
        margin: 20px 0;

        img {
          max-width: 100px;
          height: initial;
        }

        .big-title {
          color: #f2d436;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 22px;
          margin: 15px 0;
        }

        .small-description {
          color: #fff;
          line-height: 1.2;
          font-size: 14px;
        }
      }
    }
  }

  .brand-slider {
    .title-container {
      @include title-container;
    }

    .brand-slider-wrapper {
      padding: 10px 0 100px;
      position: relative;

      .uk-slider-container {
        max-height: 350px;
        margin: 0 100px;
      }

      @include slider-nav;

      .uk-slider-items {
        li {
          width: 20%;
          display: flex;
          align-items: flex-end;

          .slide {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
              max-width: 100px;
              max-height: 50px;
              object-fit: contain;
            }

            .car-brand {
              display: block;
              width: 100%;
              text-align: center;
              padding-top: 20px;
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  .confidence {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    min-height: 450px;
    background-size: cover;
    background-position: center 15%;
    position: relative;
    padding-bottom: 60px;

    .banner-img {
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .container {
      z-index: 2;
    }

    &::before {
      z-index: 1;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
    }

    .title-container {
      @include title-container;
      color: white;
    }

    .items-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .item {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        max-width: 200px;
        margin: 20px 0;

        img {
          max-width: 100px;
          height: initial;
        }

        .big-title {
          color: #f2d436;
          text-transform: uppercase;
          font-weight: 900;
          font-size: 42px;
          margin: 15px 0;
        }

        .sm-description {
          color: #fff;
          line-height: 1.2;
          font-size: 24px;
        }
      }
    }
  }

  .blog {
    background: #f6f6f6;
    padding-bottom: 75px;


    .title-container {
      @include title-container;
    }

    .blog-row {
      .blog-slider {
        position: relative;
        @include slider-nav;

        .uk-slider-container {
          margin: 0 100px;
        }

        .uk-slider-items {
          li {
            width: calc(100% / 3);
            padding: 15px;
          }
        }

        .blog-item {
          background: #fff;
          box-shadow: 0px 4px 15px #cecece;
          border-radius: 10px;
          overflow: hidden;

          &:hover {
            box-shadow: 0px 4px 5px #cecece;
          }


          .img {
            position: relative;
            overflow: hidden;
            border-radius: 10px 10px 0 0;
            height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              object-fit: cover;
              height: 200px;
            }
          }

          .content-card {
            padding: 10px;
            font-size: 18px;
            line-height: 1.5;

            .title {
              font-weight: 700;
              font-size: 16px;
              text-transform: uppercase;

              a {
                font-weight: 900;
                text-decoration: none;
                color: #000;
                transition: all .3s;

                &:hover {
                  color: $link-hover;
                }
              }
            }

            .description {
              line-height: 1.2;
              margin: 10px 0 20px;
              word-wrap: break-word;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            .infos {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;

              .info {
                padding-left: 25px;

                &.look {
                  display: flex;
                  justify-content: flex-start;
                  position: relative;

                  &:before {
                    content: '';
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    background-image: url(/assets/img/view-icon.png);
                    background-repeat: no-repeat;
                    background-size: 20px;
                    left: 2px;
                    top: 6px;
                  }
                }

                &.date {
                  display: flex;
                  justify-content: flex-end;
                  position: relative;

                  &:before {
                    content: '';
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    background-image: url(/assets/img/calendar-icon.png);
                    background-repeat: no-repeat;
                    background-size: 12px;
                    left: 8px;
                    top: 5px;
                    filter: invert(.3);
                  }
                }
              }

            }
          }
        }
      }
    }
  }

  .comments {
    background: #deecf9;
    padding-bottom: 60px;

    .title-container {
      @include title-container;
    }

    .comments-slider {
      position: relative;
      @include slider-nav;

      .nav-item {
        top: calc(50% - 15px);
      }

      .uk-slider-nav {
        justify-content: center;
        padding: 20px 0;

        li {
          a {
            width: 15px;
            height: 15px;
            background: #ddd;
          }
        }

        li.uk-active {
          a {
            background: #f3d127;
          }
        }
      }


      .uk-slider-container {
        margin: 0 100px;
        padding: 20px 0;
      }

      .uk-slider-items {
        li {
          width: calc(100% / 2.99);
          padding: 15px;
        }

        li.uk-active {
          .comment {
            opacity: 1;
            zoom: 1;
            transition: 0.3s;
          }
        }
      }

      .comment {
        transition: 0.3s;
        border-radius: 10px;
        padding: 30px 20px;
        box-shadow: 0px 4px 20px #cecece;
        background: #fff;
        opacity: .4;
        zoom: .9;

        .top-line {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding-bottom: 10px;
          padding-top: 20px;
          position: relative;

          .name-simbol {
            position: absolute;
            left: 0;
            top: -50px;
            padding: 15px;
            border-radius: 100px;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 30px;

            &.color-0 {
              background-color: #6d54be;
            }

            &.color-1 {
              background-color: #6d54be;
            }

            &.color-2 {
              background-color: #e89b40;
            }

            &.color-3 {
              background-color: #99c459;
            }
          }

          .name {
            font-weight: 900;
            font-size: 16px;
          }

          .stars {
            display: flex;

            img {
              object-fit: contain;
            }
          }
        }

        .about {
          word-wrap: break-word;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden;
          line-height: 1.2;
        }
      }
    }

  }

  .text {
    background: #f6f6f6;
    padding: 50px 0;

    .text-content {
      font-size: 18px;
      line-height: 1.5;

      h1 {
        font-size: 28px;
        font-weight: 700;
        line-height: 1.2;
        padding-bottom: 25px;
        display: block;
        text-align: center;
        width: 100%;
        box-sizing: border-box;
        text-transform: uppercase;
      }

      p, span, table, ul, li, ol, a {
        margin-bottom: 15px;
        font-size: 18px;
        line-height: 1.5;
      }

      ul, ol {
        padding-left: 35px;
      }

      h2 {
        font-size: 22px;
        font-weight: 700;
        line-height: 1.2;
        padding-bottom: 15px;
        padding-top: 15px;
        display: block;
        width: 100%;
        box-sizing: border-box;
        text-transform: uppercase;
      }

      h3 {
        font-size: 20px;
        font-weight: 700;
        line-height: 1.2;
        padding-bottom: 15px;
        padding-top: 15px;
        display: block;
        width: 100%;
        box-sizing: border-box;
        text-transform: uppercase;
      }

      h4 {
        font-size: 18px;
        font-weight: 700;
        line-height: 1.2;
        padding-bottom: 15px;
        padding-top: 15px;
        display: block;
        width: 100%;
        box-sizing: border-box;
        text-transform: uppercase;
      }

      table {
        width: 100%;
        color: #000;
        margin: 20px 0;
        min-width: 650px;
        background-color: transparent;

        tr {
          min-height: 35px;
          display: flex;
          align-items: center;
          background-color: #fff;
        }

        tr:nth-child(2n+1) {
          background-color: #e7e7e7;
        }

        td {
          min-height: 35px;
          width: 100%;
          flex-basis: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 10px 25px;
        }
      }

    }
  }
}

@media (max-width: 1350px) {
  .welcome-page {
    .brand-slider {
      .brand-slider-wrapper {
        .uk-slider-items {
          li {
            width: 25%;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .welcome-page {
    .upper-price {
      .items-row {
        justify-content: center;

        .item {
          margin: 20px;
        }
      }
    }

    .comments {
      .comments-slider {
        .uk-slider-items {
          li {
            width: 50%;

            .comment {
              opacity: 1;
              zoom: 1;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1130px) {
  .welcome-page {
    .auto-tabs {
      .tabs-content {
        .cars {
          justify-content: space-around;

          .item {
            width: 47%;
            max-width: 47%;

            .car-link {
              img {
                height: initial;
              }
            }
          }
        }
      }
    }

    .blog {

      .blog-row {
        .blog-slider {
          .uk-slider-container {

          }

          .uk-slider-items {
            li {
              width: 50%;
            }
          }

        }
      }
    }
  }
}

@media (max-width: 1050px) {
  .welcome-page {
    .banner {
      background-position: left;


      .banner-info {
        justify-content: center;
        top: 0;
        flex-direction: column;
        align-items: center;

        &:before {
          display: none;
        }

        .rent-form-wrapper {
          order: 2;
          width: 100%;
          justify-content: center;
          display: flex;
        }

        .item.info-text {
          padding: 20px 0;
          order: 1;

          .title-big {
            font-size: 28px;
            line-height: 1;
            text-align: center;
            font-weight: 700;
          }

          .title-sm {
            text-align: center;
            font-weight: 300;
            font-size: 20px;
            line-height: 1;
            padding: 10px 0;
          }

          .btn {
            display: none;
          }

          .search-block {
            display: none;
          }
        }

        .mob-btn {
          display: block;
          width: 100%;
          order: 3;
          margin: 20px 0;

          .btn-pattern-blue {
            display: flex;
            justify-content: center;

            .btn-pattern-blue {

              @include btn-pattern-blue;
              padding: 5px 10px;
            }

          }
        }
      }
    }

    .auto-tabs {
      padding-top: 20px;
    }
  }
}

@media (max-width: 992px) {
  .welcome-page {
    .banner {
      .banner-img {
        display: none;
      }

      &:before {
        display: none;
      }

      .banner-info {
        .item.info-text {
          .title-big, .title-sm {
            color: black;
          }
        }
      }
    }

    .auto-tabs {
      .tabs-nav {

        li {
          margin-right: 5px;

          &:last-child {
            margin-right: 0;
          }

          a {
            font-size: 16px;
            padding: 5px;
            white-space: nowrap;
          }
        }
      }
    }

    .brand-slider {
      .brand-slider-wrapper {
        .uk-slider-container {
          margin: 0 50px;
        }


      }
    }

    .blog {


      .blog-row {
        .blog-slider {
          .uk-slider-container {
            margin: 0 50px;
          }


        }
      }
    }

    .comments {
      .comments-slider {
        .uk-slider-container {
          margin: 0 50px;
        }
      }
    }
  }
}

@media (max-width: 860px) {
  .welcome-page {
    .confidence {
      .items-row {
        .item {
          max-width: initial;
          width: 40%;
          margin: 10px;

          .big-title {
            font-size: 20px;
          }

          .sm-description {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .welcome-page {
    .auto-tabs {
      .tabs-nav {
        border: none;
      }

      .tabs-content {
        .cars {
          .item {
            .btn-pattern-tabs.btn-tabs {
              .btn-pattern-tabs {
                font-size: 3vw;
              }
            }

            .car-link {
              img {
                width: initial;
              }
            }

            .car-name {
              font-size: 5vw;
              padding-bottom: 10px;
            }

            .car-prise {
              font-size: 5vw;
              padding-bottom: 20px;

              b {
                font-size: 5vw;
              }
            }

          }
        }
      }
    }

    .upper-price {

      .items-row {
        .item {
          max-width: initial;
          width: 40%;
          margin: 10px;

          img {
            max-width: 75px;
          }

          .big-title {
            font-size: 18px;
          }

          .small-description {
            display: none;
          }

        }
      }
    }

    .brand-slider {
      .title-container{
        padding-bottom: 25px;
      }
      .brand-slider-wrapper {
        .uk-slider-items {
          li {
            width: 33.333333%;
          }
        }
      }
    }

    .blog {
      .blog-row {
        .blog-slider {
          .uk-slider-items {
            li {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .welcome-page {
    .comments {
      .comments-slider {
        .uk-slider-items {
          li {
            width: 100%;
          }
        }
      }
    }
  }

}

@media (max-width: 650px) {
  .welcome-page {
    overflow-x: hidden;

    .text {
      .text-content {
        .table {
          width: 100%;
          overflow-x: scroll;
        }

      }
    }

  }

}

@media (max-width: 576px) {
  .welcome-page {
    .brand-slider {
      .brand-slider-wrapper {
        .uk-slider-container {
          margin: 0;
        }

        .uk-slider-items {
          li {
            width: 50%;
          }
        }
      }
    }

    .confidence {
      .items-row {
        .item {
          .sm-description {
            font-size: 14px;
          }
        }
      }
    }

    .blog {
      .blog-row {
        .blog-slider {
          .uk-slider-container {
            margin: 0 20px;
          }
        }
      }
    }

    .comments {
      .comments-slider {
        .uk-slider-container {
          margin: 0 20px;
        }

      }
    }
  }
}